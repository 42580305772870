// /* variables::fonts::secondary
// --------------------------------------------------------------------------------------------------------------------- */
// @font-face {
//   font-family: 'Manrope', sans-serif;
//   src: url('/themes/goodyear-power/assets/css/fonts/Manrope-Bold.eot');
//   src:
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Bold.eot') format('embedded-opentype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Bold.woff') format('woff'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Bold.ttf') format('truetype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Bold.svg') format('svg');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Manrope', sans-serif;
//   src: url('/themes/goodyear-power/assets/css/fonts/Manrope-SemiBold.eot');
//   src:
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-SemiBold.eot') format('embedded-opentype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-SemiBold.woff') format('woff'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-SemiBold.ttf') format('truetype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-SemiBold.svg') format('svg');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Manrope', sans-serif;
//   src: url('/themes/goodyear-power/assets/css/fonts/Manrope-Medium.eot');
//   src:
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Medium.eot') format('embedded-opentype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Medium.woff') format('woff'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Medium.ttf') format('truetype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Medium.svg') format('svg');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Manrope', sans-serif;
//   src: url('/themes/goodyear-power/assets/css/fonts/Manrope-Regular.eot');
//   src:
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Regular.eot') format('embedded-opentype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Regular.woff') format('woff'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Regular.ttf') format('truetype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Regular.svg') format('svg');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Manrope', sans-serif;
//   src: url('/themes/goodyear-power/assets/css/fonts/Manrope-Light.eot');
//   src:
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Light.eot') format('embedded-opentype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Light.woff') format('woff'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Light.ttf') format('truetype'),
//     url('/themes/goodyear-power/assets/css/fonts/Manrope-Light.svg') format('svg');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }
